import React from "react"

import SEO from "../components/seo"
import withState from "../util/withState"
import Gallery from "../components/partials/gallery"
import { Link } from "gatsby"
const IndexPage = () => {
  return (
    <>
      <SEO title="Home" keywords={[`mazrui`, `careers`]}/>
      <content>
        <div className="grey-bg p-2">
          <div className="container py-3 py-md-5">
            <div className="row">
              <div className="col-md-12 mb-md-5">
                <h2 className="mb-2 mb-md-4 text-blue xl">Career Opportunities</h2>
                <p className="pl-md-3">
                  Mazrui International is a diversified business group in the UAE, with business
                  interests in Oil & Gas, Real Estate, Trading, Financial Investments, Education,
                  Construction & Logistics, and Retail. Our company nurtures future leaders and
                  innovators as we continue to expand globally. Mazrui Group brings endless
                  opportunities for career advancement and welcomes people with a heart to serve
                  and a desire to learn.
                  <br/><br/>
                  We offer attractive opportunities for career development and learning journey.
                  We are looking for people who share our core values, who are reliable,
                  committed, and respectful.
                  <br/><br/>
                  Build a career with us and be part of a great place to work!
                </p>
              </div>
            </div>
            <div className="row bg-white position-relative mt-4">
              <div className="career-box">
                <div className="">
                  <h2 className="mb-3">
                    Build a career with us and be part of a <br/>
                    <strong>A Great Place To Work For All!</strong>
                  </h2>
                  <Link to="/job-search" className="nav-link active" role="tab"> <img alt="opportunity link" src="/btn-oppertunity.png"/></Link>
                </div>
              </div>
              <div className="col-md-7 offset-md-5 col-lg-8 offset-lg-4">
                <img src="/career-bg.jpg" title="Career Opportunities" alt="Career Opportunities"
                     className="w-100 negativeMargin"/>
              </div>
            </div>


            <div className="row mt-5 mb-5">
              <div className="col-md-5 align-self-center">
                <h2 className="mb-2 mb-md-4 text-blue xl">Great Place<br/>
                  <span className="ml-5 pl-5">to Work</span></h2>
                <p>Our work environment encourage entrepreneurship and
                  innovation. Thanks to a diverse pool of professionals of
                  more than 25 nationalities, with team-oriented culture,
                  and with commitment to excellence. We offer numerous
                  exciting career, provide a positive work environment that
                  rewards excellent performance.


                </p>

              </div>
              <div className="col-md-6 offset-md-1">
                <h2 className="text-dark text-center mt-3 mt-md-0"><strong>Mazrui Group Workforce Demographics</strong>
                </h2>
                <ul className="nav nav-tabs graphs my-3 my-md-5 text-uppercase" id="myTab">
                  <li className="nav-item">
                    <Link to="#company" className="nav-link active" role="tab"> Company</Link>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="company" role="tabpanel" aria-labelledby="home-tab">
                    <img src="/graph.jpg" title="Company" alt="Company" className="w-100"/>
                  </div>
                  <div className="tab-pane fade" id="leadership" role="tabpanel"
                       aria-labelledby="profile-tab">Leadership
                  </div>
                  <div className="tab-pane fade" id="creative" role="tabpanel" aria-labelledby="contact-tab">Creative
                    and corporate
                  </div>
                  <div className="tab-pane fade" id="tech" role="tabpanel" aria-labelledby="contact-tab">Tech</div>
                </div>
              </div>
            </div>
            <hr/>

            <div className="row mt-5">

              <div className="col-md-6">
                <iframe className="video-player" src="https://www.youtube.com/embed/12ExW-ohTp4" frameBorder="0"
                        title="Mazrui Homepage video"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
              </div>
              <div className="col-md-6 align-self-center pl-md-5">
                <h2 className="mb-2 mb-md-4 text-blue xl">Happily at Work</h2>
                <p className="ml-md-3">Out-of-office inspiration, learning, connection with
                  Colleagues at our various company events. Being
                  happily at work is a positive emotion that drives our
                  ambitions, helping us work better collectively,
                  harmoniously and successfully.
                </p>
              </div>
            </div>
            <Gallery/>
          </div>
        </div>
      </content>
    </>
  )
}

export default withState(IndexPage)
