import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons"

const GalleryItem = ({alt,imageURL,src}) => {
 return(
   <div>
    <Link data-fancybox="gallery" to={imageURL}>
      <img alt={alt} src={src} className="w-100"/>
      <span>
          <FontAwesomeIcon icon={faSearchPlus} className="fas"/>
        </span>
    </Link>
  </div>
 )
};

export default GalleryItem
