import React, { useEffect } from "react"
import { Link } from "gatsby"
import $ from "jquery"
// eslint-disable-next-line
loadJquery()
// eslint-disable-next-line
import Slider from "react-slick"
// eslint-disable-next-line
import GalleryItem from "./gallery-item"
// eslint-disable-next-line
require("@fancyapps/fancybox")
// import fancybox from "@fancyapps/fancybox";
// eslint-disable-next-line
import "@fancyapps/fancybox/dist/jquery.fancybox.min.css"

function PrevArrow(props) {
  const { style, onClick } = props
  return (
    // eslint-disable-next-line no-script-url
    <Link to="javascript:void(0);" className={`btnPrev slick-arrow`} onClick={onClick} style={{ ...style }}>
      <img src="/arrow_left_circle-512.png" style={{ width: "39px" }} alt="arrow-left-circle"/>
    </Link>
  )
}

function loadJquery() {
  if (typeof window !== `undefined`) {
    window.jQuery = $
  }
}

function NextArrow(props) {
  const { style, onClick } = props
  return (
    // eslint-disable-next-line no-script-url
    <Link to="javascript:void(0);" className={`btnNext slick-arrow`} onClick={onClick} style={{ ...style }}>
      <img src="/arrow_left_circle-512.png" style={{ width: "39px", transform: "rotate(180deg)" }}
           alt="arrow-left-circle"/>
    </Link>
  )
}

const images = [{
  to: "/gallery/gallery_00.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_00.jpg",
}, {
  to: "/gallery/gallery_01.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_01.jpg",
}, {
  to: "/gallery/gallery_02.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_02.jpg",
}, {
  to: "/gallery/gallery_03.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_03.jpg",
}, {
  to: "/gallery/gallery_04.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_04.jpg",
}, {
  to: "/gallery/gallery_05.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_05.jpg",
}, {
  to: "/gallery/gallery_06.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_06.jpg",
}, {
  to: "/gallery/gallery_07.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_07.jpg",
}, {
  to: "/gallery/gallery_08.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_08.jpg",
}, {
  to: "/gallery/gallery_09.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_09.jpg",
}, {
  to: "/gallery/gallery_10.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_10.jpg",
}, {
  to: "/gallery/gallery_11.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_11.jpg",
}, {
  to: "/gallery/gallery_12.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_12.jpg",
}, {
  to: "/gallery/gallery_13.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_13.jpg",
}, {
  to: "/gallery/gallery_14.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_14.jpg",
}, {
  to: "/gallery/gallery_15.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_15.jpg",
}, {
  to: "/gallery/gallery_16.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_16.jpg",
}, {
  to: "/gallery/gallery_17.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_17.jpg",
}, {
  to: "/gallery/gallery_18.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_18.jpg",
}, {
  to: "/gallery/gallery_19.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_19.jpg",
}, {
  to: "/gallery/gallery_20.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_20.jpg",
}, {
  to: "/gallery/gallery_21.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_21.jpg",
}, {
  to: "/gallery/gallery_22.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_22.jpg",
}, {
  to: "/gallery/gallery_23.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_23.jpg",
}, {
  to: "/gallery/gallery_24.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_24.jpg",
}, {
  to: "/gallery/gallery_25.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_25.jpg",
}, {
  to: "/gallery/gallery_26.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_26.jpg",
}, {
  to: "/gallery/gallery_27.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_27.jpg",
}, {
  to: "/gallery/gallery_28.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_28.jpg",
}, {
  to: "/gallery/gallery_29.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_29.jpg",
}, {
  to: "/gallery/gallery_30.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_30.jpg",
}, {
  to: "/gallery/gallery_31.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_31.jpg",
}, {
  to: "/gallery/gallery_32.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_32.jpg",
}, {
  to: "/gallery/gallery_33.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_33.jpg",
}, {
  to: "/gallery/gallery_34.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_34.jpg",
}, {
  to: "/gallery/gallery_35.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_35.jpg",
}, {
  to: "/gallery/gallery_36.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_36.jpg",
}, {
  to: "/gallery/gallery_37.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_37.jpg",
}, {
  to: "/gallery/gallery_38.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_38.jpg",
}, {
  to: "/gallery/gallery_39.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_39.jpg",
}, {
  to: "/gallery/gallery_40.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_40.jpg",
}, {
  to: "/gallery/gallery_41.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_41.jpg",
}, {
  to: "/gallery/gallery_42.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_42.jpg",
}, {
  to: "/gallery/gallery_44.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_44.jpg",
}, {
  to: "/gallery/gallery_45.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_45.jpg",
}, {
  to: "/gallery/gallery_46.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_46.jpg",
}, {
  to: "/gallery/gallery_47.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_47.jpg",
}, {
  to: "/gallery/gallery_48.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_48.jpg",
}, {
  to: "/gallery/gallery_49.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_49.jpg",
}, {
  to: "/gallery/gallery_50.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_50.jpg",
}, {
  to: "/gallery/gallery_51.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_51.jpg",
}, {
  to: "/gallery/gallery_52.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_52.jpg",
}, {
  to: "/gallery/gallery_53.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_53.jpg",
}, {
  to: "/gallery/gallery_54.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_54.jpg",
}, {
  to: "/gallery/gallery_55.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_55.jpg",
}, {
  to: "/gallery/gallery_56.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_56.jpg",
}, {
  to: "/gallery/gallery_57.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_57.jpg",
}, {
  to: "/gallery/gallery_58.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_58.jpg",
}, {
  to: "/gallery/gallery_59.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_59.jpg",
}, {
  to: "/gallery/gallery_60.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_60.jpg",
}, {
  to: "/gallery/gallery_61.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_61.jpg",
}, {
  to: "/gallery/gallery_62.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_62.jpg",
}, {
  to: "/gallery/gallery_63.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_63.jpg",
}, {
  to: "/gallery/gallery_64.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_64.jpg",
}, {
  to: "/gallery/gallery_65.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_65.jpg",
}, {
  to: "/gallery/gallery_66.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_66.jpg",
}, {
  to: "/gallery/gallery_67.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_67.jpg",
}, {
  to: "/gallery/gallery_68.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_68.jpg",
}, {
  to: "/gallery/gallery_69.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_69.jpg",
}, {
  to: "/gallery/gallery_70.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_70.jpg",
}, {
  to: "/gallery/gallery_71.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_71.jpg",
}, {
  to: "/gallery/gallery_72.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_72.jpg",
}, {
  to: "/gallery/gallery_73.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_73.jpg",
}, {
  to: "/gallery/gallery_74.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_74.jpg",
}, {
  to: "/gallery/gallery_75.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_75.jpg",
}, {
  to: "/gallery/gallery_76.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_76.jpg",
}, {
  to: "/gallery/gallery_77.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_77.jpg",
}, {
  to: "/gallery/gallery_78.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_78.jpg",
}, {
  to: "/gallery/gallery_79.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_79.jpg",
}, {
  to: "/gallery/gallery_80.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_80.jpg",
}, {
  to: "/gallery/gallery_81.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_81.jpg",
}, {
  to: "/gallery/gallery_82.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_82.jpg",
}, {
  to: "/gallery/gallery_83.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_83.jpg",
}, {
  to: "/gallery/gallery_84.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_84.jpg",
}, {
  to: "/gallery/gallery_85.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_85.jpg",
}, {
  to: "/gallery/gallery_86.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_86.jpg",
}, {
  to: "/gallery/gallery_87.jpg",
  alt: "mazrui gallery slider image",
  src: "/gallery/gallery_87.jpg",
}]
const Gallery = () => {

  useEffect(() => {
    $("[data-fancybox=\"images\"]").fancybox({
      buttons: ["slideShow", "share", "zoom", "fullScreen", "close"],
      thumbs: {
        autoStart: true,
      },
    })
  }, [])
  const sliderSettings = {
    className: "slider gallery-slider",
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1500,
    arrows: true,
    dots: false,
    prevArrow: <PrevArrow/>,
    nextArrow: <NextArrow/>,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          arrows: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          arrows: true,
        },
      },
    ],
  }
  return (
    <div className="row mt-5">
      <div className="col-md-12">
        <h2 className="mb-2 mb-md-4 text-blue xl">Mazrui Group Photos</h2>
        <Slider
          {...sliderSettings}
        >
          {images.map((image, key) =>
            <GalleryItem key={image.to} imageURL={image.to} alt={`${image.alt} ${key}`} src={image.src}/>,
          )}
        </Slider>
      </div>
    </div>
  )
}

export default Gallery
